@import "typography";
@import "breakpoints";
@import url("https://use.typekit.net/eyi7uco.css");

$base: 8px;
$red: #de0000;

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
}

.nav {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  z-index: 9999;
  &__logo {
    margin-right: $base * 3;
    padding-right: $base * 3;
    width: 90px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 2px;
      height: 50px;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      border-right: 1px solid rgba(255, 255, 255, 0.7);
    }
    @include respond-to("medium-down") {
      width: 160px;
    }
  }
  &__block {
    display: inline-flex;
    align-items: center;
  }
  &__subtext {
    color: #fff;
    @include font_1;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    @include respond-to("medium-down") {
      font-size: 20px;
    }
  }
}

.nav-container {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  transition: 0.2s ease all;
  &.show {
    z-index: 9999;
    opacity: 1;
    //visibility: visible;
    transition: 0.2s ease all;
  }
}

.main-nav {
  width: 100%;
  height: 80px;
  -webkit-box-shadow: -1px 9px 12px -9px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -1px 9px 12px -9px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 9px 12px -9px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  background: #fff;
  z-index: 800;
  &__wrap {
    width: 100%;
    height: 80px;
    max-width: 1400px;

    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
  &__logo {
    margin-right: $base * 3;
    padding-right: $base * 3;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    width: 90px;
    @include respond-to("medium-down") {
      width: 130px;
    }
  }
  &__block {
    display: inline-flex;
    align-items: center;
  }
  &__subtext {
    color: #3f3f3f;
    @include font_1;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    @include respond-to("medium-down") {
      font-size: 18px;
    }
  }
}

.hero {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-image: url("../images/hero-mobile.jpg");
  @include respond-to("medium-down") {
    background-position: center;
    background-image: url("../images/grit-hero.jpg");
  }
  &__image {
    width: 150px;
    display: none;
    @include respond-to("medium-down") {
      width: 400px;
      display: block;
    }
  }
  &__background {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &__wrap {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__content {
    width: 100%;
    @include respond-to("medium-down") {
      width: 60%;
      margin-left: $gutter * 6;
    }
  }
}

.col_wrap {
  @include respond-to("medium-down") {
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-gap: $base * 5;
  }
}

.container {
  max-width: 1240px;
  width: 100%;
  margin: auto;
  padding: 20px;
}

section {
  padding-top: $gutter * 2;
  padding-bottom: $gutter * 2;
  @include respond-to("medium-down") {
    padding-top: $gutter * 4;
    padding-bottom: $gutter * 4;
  }
}

.section {
  &__title {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 400;
    @include font_3;
    position: relative;
    margin-bottom: $base * 8;
    @include respond-to("medium-down") {
      font-size: 28px;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: -24px;
      left: 0;
      width: 40px;
      border-bottom: solid 6px $red;
      @include respond-to("medium-down") {
        border-bottom: solid 8px $red;
        width: 70px;
      }
    }
  }
  &__block {
    h4 {
      @include font_1;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 2px;
      color: $red;
      margin-bottom: $base * 2;
      margin-top: $base * 4;
    }
  }
  &__trailer {
    margin-top: $base * 4;
  }
  &__header {
    @include font_2;
    font-size: 36px;
    line-height: 1.25;
    margin-bottom: $base * 4;
    @include respond-to("medium-down") {
      font-size: 54px;
      line-height: 1.15;
    }
  }
  &__copy {
    @include font_3;
    font-weight: 300;
    font-size: 18px;
    p {
      line-height: 1.7;
    }
  }
}
.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframe-wrap {
  // padding: 56.25% 0 0 0;
  position: relative;
}

.interactive {
  margin-top: $base * 4;
  display: none;
  @include respond-to("medium-down") {
    display: block;
  }
  &__hover {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease all;
    position: absolute;
    text-transform: initial;
    font-size: 18px;
    font-weight: 300;
    max-width: 350px;
    margin-top: $base * 3;
    line-height: 24px;
  }
  &__line {
    width: 100%;
    height: 2px;
    background: $red;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    color: #fff;
    margin-top: $base * 3;
  }
  &__heading {
    cursor: pointer;
    span {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: -34px;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 20px;
        background: $red;
        border-radius: 50px;
        transition: 0.2s ease all;
      }
    }
    &:hover {
      .interactive__hover {
        visibility: visible;
        opacity: 1;
        transition: 0.2s ease all;
      }
      span {
        &:before {
          background: #fff;
          transition: 0.2s ease all;
        }
      }
    }
  }
  &__block {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    @include respond-to("medium-down") {
      font-size: 22px;
    }
  }
}

.why-grit {
  padding-top: $base * 2 !important;
  &__wrap {
    padding-top: 80px;
  }
  &__graph {
    p {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .button_block {
    max-width: 300px;
    margin: $base * 6 auto;
  }
  .button_wrap {
    max-width: 350px;
    width: 100%;
    display: none;
    @include respond-to("medium-down") {
      display: block;
      margin: $base * 6 auto;
    }
  }
  .button_wrap_mobile {
    display: block;
    margin-top: $base * 4;
    @include respond-to("medium-down") {
      display: none;
    }
  }
  &__sidebar {
    display: none;
    @include respond-to("medium-down") {
      display: block;
    }
  }
}

.small_button_block {
  max-width: 75%;
  margin: auto;
  button {
    background: none;
    border-top: solid 3px $red;
    border-bottom: solid 3px $red;
    border-left: 0;
    border-right: 0;
    box-shadow: none;
    outline: none;
    font-weight: 800;
    font-size: 16px;
    @include font_3;
    color: #3f3f3f;
    letter-spacing: 2px;
    padding: $base * 2 0;
    margin-top: $base * 4;
    width: 100%;
    @include respond-to("medium-down") {
      font-size: 16px;
    }
    span {
      @include font_4;
      font-weight: 300;
      font-style: italic;
    }
  }
}

.button_block {
  button {
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
    font-weight: 800;
    font-size: 16px;
    @include font_3;
    color: #fff;
    letter-spacing: 2.5px;
    width: 100%;
    @include respond-to("medium-down") {
      font-size: 18px;
    }
    span {
      @include font_4;
      font-weight: 300;
      font-style: italic;
    }
  }
}

.dark_bg {
  background: #000;
  color: #fff;
  &__header {
    @include font_2;
    color: #fff;
  }
  &__copy {
    p {
      color: #fff;
    }
  }
}

.white_bg {
  background: #fff;
  color: #3f3f3f;
  &__header {
    @include font_2;
    color: #3f3f3f;
  }
  &__copy {
    p {
      color: #3f3f3f;
    }
  }
}

.interactive-mobile {
  margin-top: $base * 6;
  color: #fff;
  @include respond-to("medium-down") {
    display: none;
  }
  &__block {
    margin-bottom: $base * 3;
    transition: 0.2s ease all;
    height: 50px;
    &:hover {
      height: auto;
      transition: 0.2s ease all;
      .interactive-mobile__hover {
        visibility: visible;
        opacity: 1;
        transition: 0.2s ease all;
      }
    }
  }
  &__bullet {
    width: 15px;
    height: 15px;
    background: $red;
    display: inline-block;
    border-radius: 50px;
    margin-right: $base * 2;
  }
  &__title {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
  &__hover {
    text-transform: unset;
    transition: 0.2s ease all;
    visibility: hidden;
    opacity: 0;
    margin-left: $base * 4;
    margin-top: $base * 2;
    line-height: 1.4;
  }
}

.nav-modal {
  width: 100%;
  height: 100vh;
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  transition: ease 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  &__wrap {
    max-width: 1400px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
  }
}

.nav-close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  height: 100px;
  max-width: 1400px;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.nav-content {
  max-width: 1400px;
  margin: auto;
  li,
  a {
    color: #fff;
    font-size: 32px;
    margin-bottom: $base * 7;
    transition: ease 0.2s all;
    cursor: pointer;
    &:hover {
      color: $red;
      transition: ease 0.2s all;
    }
  }
  ul {
    text-align: center;
  }
}

.mission {
  background-image: url("../images/jake-bailey-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  &__logo {
    max-width: 100px;
    width: 100%;
    margin-bottom: $base * 5;
    @include respond-to("medium-down") {
      max-width: 350px;
      margin: auto;
    }
  }
}

section.article {
  // padding-top: $base * 3;
}

.article {
  padding-top: 110px;
  margin-bottom: $base * 4;
  z-index: 0;
  min-height: calc(100vh - 100px);
  &__wrap {
    @include respond-to("medium-down") {
      display: grid;
      grid-template-columns: 4fr 2fr;
      grid-gap: $base * 4;
    }
  }
  &__sidebar {
    padding-left: 0;
    @include respond-to("medium-down") {
      padding-left: $base * 3;
      border-left: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
  &__title {
    font-family: lust-didone, Georgia, "Times New Roman", Times, Serif;
    font-size: 32px;
    margin-top: $base * 3;
    margin-bottom: $gutter;
  }
  &__name {
    font-weight: 600;
    font-size: 18px;
  }
  &__block {
    margin-top: $base * 2;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  &__share {
    display: flex;
    button {
      width: 40px;
      margin-left: $base;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__description {
    margin-top: $base * 3;
    padding-top: $base * 3;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 26px;
  }
  &__tags {
    margin-left: $base * 6;

    span {
      width: 100px;
      height: 20px;
      background: #e9eff3;
      color: #0695e5;
      font-weight: 600;
      margin: 0 $base/2;
      padding: 6px 16px;
      border-radius: 50px;
      font-size: 13px;
      text-transform: uppercase;
      &:first-child {
        background: transparent;
        color: #3f3f3f;
        font-weight: 600;
        padding: 0;
      }
    }
  }
}

.patron-message {
  &__wrap {
    width: 100%;
    @include respond-to("medium-down") {
      width: 60%;
    }
  }
  &__title {
    font-family: lust, serif;
    font-weight: 500;
    font-style: normal;
    font-size: 26px;
    line-height: 1.25;
    margin-bottom: $base * 4;
    @include respond-to("medium-down") {
      font-size: 36px;
    }
    span {
      color: $red;
    }
  }
}

.sidebar {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  @include respond-to("medium-down") {
    overflow-y: scroll;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
  &__wrap {
    // overflow: hidden;
    margin-top: $base * 2;
    @include respond-to("medium-down") {
      height: 660px;
    }
  }
  &__header {
    font-weight: 800;
    font-size: 18px;
    margin-bottom: $base * 2;
  }
  &__row {
    display: grid;
    grid-template-columns: 1fr 6fr;
    padding: $base * 2 0;
    grid-gap: $gutter;
    cursor: pointer;
    transition: 0.2s all ease;
    @include respond-to("medium-down") {
      padding: $base * 2;
    }
    &:hover {
      background: #eee;
      transition: 0.2s all ease;
      color: $red;
    }
  }
  &__title {
    font-weight: 600;
    line-height: 20px;
  }
  &__image {
    position: relative;
    height: 75px;
    width: 106px;
    svg {
      z-index: 100;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.9;
    }
    img {
      width: 100%;
      height: 80px;
    }
  }
  &__name {
    font-size: 14px;
    font-weight: 400;
    margin-top: $base;
  }
}

.scroll-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, -50%);
  margin-bottom: $base * 5;
  .button_block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.insights {
  &__logo_wrap {
    width: 300px;
    @include respond-to("medium-down") {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 558px;
    }
  }
  svg {
    margin-right: 16px;
    margin-top: -9px;
  }
  .section__title {
    margin-bottom: 0;
  }
  &__header {
    margin-top: $base * 3;
    margin-bottom: $base * 6;
    @include respond-to("medium-down") {
      display: flex;
      justify-content: center;
    }
  }
  &__subheader {
    display: flex;
    align-items: flex-start;
    margin-bottom: $base * 10;
  }
  &__play {
    width: 100px;
    justify-self: flex-end;
    opacity: 0.25;
  }
  &__logo {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    margin-bottom: $base * 2;
    max-width: 300px;
    img {
      max-width: 187px;
      @include respond-to("medium-down") {
        max-width: 300px;
      }
    }
    @include respond-to("medium-down") {
      width: 350px;
      display: flex;
      margin-bottom: 0;
    }
    span {
      @include font_1;
      white-space: nowrap;
      color: $red;
      font-size: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding-left: $base * 3;
      margin-left: $base * 3;
      border-left: 3px solid $red;
      position: relative;
      @include respond-to("medium-down") {
        font-size: 40px;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        border-left: 3px solid $red;
      }
    }
  }
  &__grit {
    width: 100px;
    @include respond-to("medium-down") {
      width: 220px;
    }
  }
}

.resources {
  padding-top: $base * 4;
  // padding-bottom: $base * 6;
  @include respond-to("medium-down") {
    padding-top: $base * 6;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $base * 4;
  }
  &__blurb {
    font-weight: 600;
    font-size: 18px;
    margin-top: $base * 2;
    color: #3f3f3f;
  }
  &__tile {
    transition: ease 0.2s all;
    cursor: pointer;
    margin-bottom: $base * 3;
    @include respond-to("medium-down") {
      margin-bottom: 0;
    }
    &:hover {
      .resources__placeholder {
        filter: brightness(1.2);
        transition: ease 0.2s all;
      }
      .resources__blurb,
      .resources__name {
        transition: ease 0.2s all;
        color: $red;
      }
    }
  }
  &__placeholder {
    position: relative;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__name {
    font-weight: 400;
    font-size: 16px;
    color: #3f3f3f;
    margin-top: $gutter;
    @include respond-to("medium-down") {
      margin-top: $base * 2;
    }
  }
}

.play {
  width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quick-links {
  &__wrap {
    list-style-type: disc;
    @include respond-to("medium-down") {
      // display: grid;
      grid-gap: $gutter;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: $base * 4;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
  &__item {
    font-size: 18px;
    color: #fff;
    margin-bottom: $base * 3;
    line-height: 1.7;
  }
}

.mentorship {
  background-image: url("../images/mentorship.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.footer {
  width: 100%;
  height: 100px;
  background: #000;
  color: #999;
  font-size: 14px;
}
